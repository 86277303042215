$primary: #b50035;
$secondary: #002f53;

$dark-primary: #990033;
$light-secondary: #1e67a8;

$success: #3adb76;
$warning: #ffae00;
$alert: #CD2626;
$light-gray: #eee;
$medium-gray: #cacaca;
$dark-gray: #333;
$black: #0a0a0a;
$white: #fff;
$black: #0a0a0a;


hr.primary {
	border: 0;
    height: 8px;
    background: $primary;
    margin: 0;
    width: 100%;
    max-width: 100%;
}

/* Banner */
.bannerSlider {
	width: 100%;
	.slide img{
		width: 100%;
	}
}
.slide:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background: -webkit-radial-gradient(top center, ellipse cover, rgba(255,255,255,0.2) 0%,rgba(0,47,83,0.8) 100%);
}

/* Menu */
.fixed {
	position: fixed;
	z-index: 99;
	top: 25px;
}
.transparent {
	background:rgba(255,255,255,.7);
}
.top-bar {
	height: 77px;
	p {
		font-size: 18px;
		font-weight: bold;
		text-align: center;
	}
}
.top-barWrapper {
	padding: 10px 0 10px 0;
}
.top-bar-title {
	width: 20% !important;
}
.top-bar-right {
	width: 78% !important;
	height: 70px;
	.menu{
		height: 100%;
	}
}
.top-bar ul {
	background-color: transparent !important;
}


/* Lists */
.dimondSquares {
	list-style-image: url(../img/bullet.png);
}
.bulletLess {
	list-style-type: none;
}

/* Common */
/* .pad20 {
	padding: 20px;
}
.pad20tb {
	padding-top: 20px;
	padding-bottom: 20px;
}
.marg20b {
	margin-bottom: 20px;
} */

.blogTile {
	img {
		outline: 2px solid white;
    	outline-offset: -9px;
	}
}


/* 			Generic Background Colors */
.primary {
	background-color: $primary;
	color: $white;
}
.secondary {
	background-color: $secondary;
	color: $white;
}
.dark-primary {
	background-color: $dark-primary;
	color: $white;
}
.light-secondary {
	background-color: $light-secondary;
	color: $white;
}
.light-gray {
	background-color: $light-gray;

	outline: 2px solid white;
    outline-offset: -9px;
}

/* Icons */
.iconWrapper {
	height: 210px;
	position: relative;
	text-align: center;
	.icon {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
	.iconText {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
}
svg.icon100 {
	height: 100px;
}

.headingSvg {
	svg {
		float: left;
		width: 50px;
		margin-right: 1rem;
	}
}
/* svg.icon25 {
	height: 50px;
	display: inline-block;
} */

.quickLinks {
	svg {
		height: 25px;
		margin-right: 1rem;
	}
}

/* Typography */
.center {
	text-align: center;
}
.bold {
	font-weight: bold;
}
.caps {
	text-transform: uppercase;
}

/*		 Text Colors */
.primaryTxt {
	color: $primary;
}
.secondaryTxt {
	color: $secondary;
}
.dark-primaryTxt {
	color: $dark-primary;
}
.light-secondaryTxt {
	color: $light-secondary;
}
.whiteTxt {
	color: $white;
}

/* Overrides */
.accordion-title::before {
    content: '+';
    position: absolute;
    right: 1rem;
    top: 50%;
    margin-top: -0.9rem !important;
    font-size: 30px;
}

@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;


// Mixin
@mixin pad($top, $right, $bottom, $left) {
  padding-top: $top;
  padding-right: $right;
  padding-bottom: $bottom;
  padding-left: $left;
}
@mixin marg($top, $right, $bottom, $left) {
  margin-top: $top;
  margin-right: $right;
  margin-bottom: $bottom;
  margin-left: $left;
}

.panel {
	width: 100%;
	div.gridRow {
		@include grid-row;	
	}
}

div {
	&.pad {
		@include pad(20px, null, 20px, null);
	}
	&.margB {
		@include marg(null, null, 20px, null);
	}
}
.padAll {
	@include pad(20px, 20px, 20px, 20px);
}
.padAllTiny {
	@include pad(6px, 6px, 6px, 6px);
}
.padT {
	@include pad(20px, null, null, null);
}
